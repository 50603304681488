
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';
import GptMixin from '@/components/gpt/GptMixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { i, row } from 'mathjs';
import { get } from 'jquery';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class WellViewDetails extends mixins(GptMixin) {
  @Prop({ type: String, required: true }) wellName?: any;

  rowData: any = [];

  dataLoading = false;

  showTooltip(t) {
    console.log('test');
    let index = -1;
    index = this.plotData.z.findIndex(
      (element) => Math.floor(element) === Math.floor(t.z)
    );

    if (index !== -1) {
      // @ts-ignore
      this.$refs.wellviewChart.showTooltip(0, index);
    }
  }

  hideTooltip() {
    // @ts-ignore
    this.$refs.wellviewChart.hideTooltip();
  }

  get gasLiftData() {
    return gptModule.gasLiftData;
  }
  getKeyMatches(obj, searchKeys) {
    const keys = Object.keys(obj);

    let result: any = null;
    keys.forEach((key) => {
      if (key) {
        if (key.toLowerCase().includes(searchKeys.toLowerCase())) {
          result = key;
        }
      }
    });

    return result;
  }

  get wellViewData() {
    return gptModule.wellViewTableData;
  }

  get wellViewPlotData() {
    return gptModule.wellViewPlotData;
  }

  plotData: any = {};

  hidePlot = false;
  zoomUpdated = 0;

  getHeader(keys, wellViewTitle) {
    console.log(keys);
    if(wellViewTitle === 'cement'){
      return[ 'top_depth', 'bottom_depth','description']
    }
    else if(wellViewTitle === 'casing'){
      return [

    "depth",
    "description",

    "inner_dia",
    "outer_dia",
"grade",
    "top_thread",
    "tvd",
    "inc",
    "azi",
    "x",
    "y",
    "z"
]
    }else if(wellViewTitle == 'tubing'){
      return [
    "depth",
    "description",

    "inner_dia",
    "outer_dia",
    "tvd",
    "inc",
    "azi",
    "x",
    "y",
    "z"
]
    }
    return keys.sort()

  }

  setupPlot() {
    console.log('changeing');
    this.$nextTick(() => {
      this.hidePlot = true;
      if (this.plotPair === 'x,y') {
        this.chartData.datasets[0].data = this.plotData.x.map((item, i) => ({
          x: item,
          y: this.plotData.y[i],
        }));
        // this.chartData.labels = this.plotData.x
      } else if (this.plotPair === 'z,y') {
        this.chartData.datasets[0].data = this.plotData.z.map((item, i) => ({
          x: item,
          y: this.plotData.y[i],
        }));
      } else if (this.plotPair === 'x,z') {
        this.chartData.datasets[0].data = this.plotData.x.map((item, i) => ({
          x: item,
          y: this.plotData.z[i],
        }));
        // this.chartData.labels = this.plotData.map(item => `${item}`);
      }

      this.chartData.datasets[0].label = this.plotPair;

      this.hidePlot = false;

      this.zoomUpdated += 1;
    });
  }

  depthKey = 'depth';
  typeKey = 'type';
  psoKey = 'pso';
  ptroKey = 'ptro';
  pscKey = 'psc';

  deleteRow(event) {
    gptModule.updateGasLiftData({
      depthValue: event[this.depthKey],
      depthKey: this.depthKey,
      typeValue: event[this.typeKey],
      typeKey: this.typeKey,
    });
  }

  async created() {
    this.dataLoading = true;

    try {
      this.rowData = this.wellViewData;

      this.plotData = this.wellViewPlotData;
      this.setupPlot();
      // Handle data processing here
    } catch (error) {
      console.error('Error fetching wellview table data:', error);
    }
    this.dataLoading = false;
  }

  colorCode = '44,230,194';

  plotPair = 'x,y';

  chartData: any = {
    datasets: [
      {
        data: [],
        label: 'Deferment',
        tension: 0,
        fil: false,
        borderWidth: 1.5,
        spanGaps: true,
        borderColor: `rgba(${this.colorCode})`,
        backgroundColor: `rgba(${this.colorCode},0.01)`,
        pointRadius: 5,
        pointHitRadius: 5,
        pointHoverRadius: 5,
      },
    ],
    // labels: [1, 2, 3, 5, 5, 3, 1, 2, 3, 5, 5, 3],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 8,
      },
    },
    zoom: {
      pan: {
        enabled: true, // Enable panning
        mode: 'xy', // Allow panning in both directions
      },
      zoom: {
        enabled: true, // Enable zooming
        mode: 'xy', // Allow zooming in both directions
        speed: 0.1, // Speed of zooming
        threshold: 2, // Minimum number of pixels to trigger zoom
      },
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [
        {
          type: 'linear',
          display: true,
          reverse: true,
          // type: 'linear',
          gridLines: {
            display: false,
          },

          ticks: {
            fontColor: '#F0F4F7',
            maxTicksLimit: 5,
            fontStyle: 'bold',
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          id: 'y-axis-0',
          display: true,
          reverse: true,
          gridLines: {
            color: 'rgba(44, 230, 194, 0.2)',
          },
          ticks: {
            reverse: true,
            fontColor: '#F0F4F7',
            maxTicksLimit: 5,
            fontStyle: 'bold',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        // Custom tooltip display function
        title: (tooltipItems, payload) => {
          console.log(tooltipItems);

          //  const dataKeys = Object.keys(this.wellViewPlotData).filter((key) => key !== 'x' && key !== 'y' && key !== 'z');
          const dataKeys = Object.keys(this.wellViewPlotData).sort();
          //  console.log(dataKeys);

          const finalString = dataKeys
            .map((key) => {
              const firstValue =
                this.wellViewPlotData[key][tooltipItems[0].index]; // Get the 0th index of each array
              return `  ${key.toUpperCase()}: ${Number(firstValue).toFixed(
                2
              )}  `; // Create a string for each key-value pair
            })
            .join('\n'); // Join them with newline characters

          return finalString;
          console.log(payload);
          if (payload.datasets[0].label === 'x,y') {
            return ` X: ${
              isNaN(tooltipItems[0].label)
                ? tooltipItems[0].label
                : Number(tooltipItems[0].label).toFixed(2)
            }`; // Display the dataset label and value
          } else if (payload.datasets[0].label === 'z,y') {
            return ` Y: ${
              isNaN(tooltipItems[0].label)
                ? tooltipItems[0].label
                : Number(tooltipItems[0].label).toFixed(2)
            }`; // Display the dataset label and value
          } else if (payload.datasets[0].label === 'x,z') {
            return ` X: ${
              isNaN(tooltipItems[0].label)
                ? tooltipItems[0].label
                : Number(tooltipItems[0].label).toFixed(2)
            }`; // Display the dataset label and value
          }
          return ` X: ${
            isNaN(tooltipItems[0].label)
              ? tooltipItems[0].label
              : Number(tooltipItems[0].label).toFixed(2)
          }`; // Display the dataset label and value
        },
        label: (tooltipItem, payload) => {
          return;
          if (payload.datasets[0].label === 'x,y') {
            return ` Y: ${
              isNaN(tooltipItem.value)
                ? tooltipItem.value
                : Number(tooltipItem.value).toFixed(2)
            }`;
          } else if (payload.datasets[0].label === 'z,y') {
            return ` Z: ${
              isNaN(tooltipItem.value)
                ? tooltipItem.value
                : Number(tooltipItem.value).toFixed(2)
            }`;
          } else if (payload.datasets[0].label === 'x,z') {
            return ` Z: ${
              isNaN(tooltipItem.value)
                ? tooltipItem.value
                : Number(tooltipItem.value).toFixed(2)
            }`;
          }
        },
      },
    },
    onClick: (evt) => {
      // eslint-disable-next-line no-underscore-dangle
      const chart = (this.$refs.defermentChart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        // eslint-disable-next-line no-underscore-dangle
        const dateIndex = item[0]._index;
        const date = this.chartData.labels[dateIndex];
      }
    },
    annotation: {
      annotations: [],
    },
  };
}
